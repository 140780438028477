var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.list),function(passage,index){return _c('div',{key:index},[(_vm.practiceType === 'composePractices')?_c('h4',[_vm._v(" "+_vm._s(passage.exam.title)+": "),_c('span',[_c('span',{staticStyle:{"color":"#ff8920"}},[_vm._v(_vm._s(passage.correctCount))]),_vm._v("/"+_vm._s(passage.exam.exam_questions.length))])]):_vm._e(),_vm._l((Math.ceil(passage.exam.exam_questions.length / _vm.lineCount)),function(i){return _c('div',{key:i,staticClass:"answer-table"},[_c('table',{staticClass:"table table-bordered"},[_vm._m(0,true),_c('tbody',_vm._l((passage.exam.exam_questions),function(question,index){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:((i - 1) * _vm.lineCount <= index && index < i * _vm.lineCount),expression:"(i - 1) * lineCount <= index && index < i * lineCount"}],key:question.order},[_c('td',{staticClass:"green-td"},[_vm._v(_vm._s(question.order))]),_c('td',[(question.userAnswer)?_c('router-link',{attrs:{"to":{
                  name: 'QuestionExplanation',
                  params: {
                    questionId: question.question_id
                  },
                  query: {
                    user_exam_id: _vm.userExamId,
                    practiceType: _vm.practiceType,
                    order: index + 1,
                    passageIndex: _vm.passageIndex + 1,
                    user_exam_answer_id: question.userAnswer
                      ? question.userAnswer.id
                      : null
                  }
                }}},[_c('div',{class:question.userAnswer.is_correct === 0 ? 'text-danger' : ''},[(question.userAnswer.answers[0])?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","disabled":question.userAnswer.user_exam_answer_analysis
                        ? false
                        : true,"content":_vm.showTime(
                        question.userAnswer.user_exam_answer_analysis
                          ? question.userAnswer.user_exam_answer_analysis
                              .answer_time
                          : 0
                      ),"placement":"top"}},[_c('div',[_vm._v(" "+_vm._s(question.userAnswer.answers[0])+" ")])]):_c('div',[_vm._v("*")])],1)]):_vm._e()],1),_c('td',[(question.userAnswer)?_c('router-link',{attrs:{"to":{
                  name: 'QuestionExplanation',
                  params: {
                    questionId: question.question_id
                  },
                  query: {
                    user_exam_id: _vm.userExamId,
                    practiceType: _vm.practiceType,
                    order: index + 1,
                    passageIndex: _vm.passageIndex + 1,
                    user_exam_answer_id: question.userAnswer
                      ? question.userAnswer.id
                      : null
                  }
                }}},[(question.userAnswer.answers[0])?[(question.userAnswer.is_correct === 1)?_c('div',{staticClass:"text-success"},[_c('i',{staticClass:"fa fa-check"})]):_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fa fa-times"})])]:_c('div',{staticClass:"text-danger"},[_c('i',{staticClass:"fa fa-times"})])],2):_vm._e()],1)])}),0)])])})],2)}),0)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',{staticClass:"rnum_1"},[_c('th',{staticClass:"green-td"},[_vm._v("#")]),_c('th',{staticClass:"green-td"},[_vm._v("Your Answer")]),_c('th',{staticClass:"green-td"},[_vm._v("Correct Answer")])])])
}]

export { render, staticRenderFns }