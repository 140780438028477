<template>
  <div class="container mt-4">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link
            :to="{ name: 'SATResults', query: { type: practiceType } }"
          >
            {{ $t("sat.SAT_Results") }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="exam">
          {{ exam.title }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div v-if="result">
      <div v-if="result.is_finished && result.is_finished == 1">
        <el-alert
          :title="$t('courses.completed')"
          type="success"
          center
          show-icon
          :closable="false"
        >
        </el-alert>
      </div>
      <div v-else>
        <el-alert
          :title="$t('courses.Incomplete')"
          type="error"
          center
          show-icon
          :closable="false"
        >
        </el-alert>
      </div>
      <el-alert
        v-if="
          this.leaveTimes > 0 ||
            (result.other_status.timeType && result.other_status.timeType > 1)
        "
        class="mt-2"
        type="info"
        center
        show-icon
        :closable="false"
      >
        <div slot="title">
          <span
            v-if="this.leaveTimes > 0"
            v-html="
              $t('toefl.leaveAlertResult', {
                counts: this.leaveCountText,
                times: this.leaveSeconds,
                second: this.second
              })
            "
          ></span>
          <el-tag
            v-if="
              result.other_status.timeType && result.other_status.timeType > 1
            "
            class="ml-2"
            size="mini"
            type="info"
            effect="dark"
          >
            <b>{{ timeTypeArr[result.other_status.timeType] }}</b>
          </el-tag>
        </div>
      </el-alert>
    </div>
    <ScoreHistory v-if="false"></ScoreHistory>
    <div class="row sat">
      <router-link
        :to="{
          name: 'SATList'
        }"
        class="col-md-5 sat_left print_div"
      >
        <p>AP</p>
        <span>®</span>
      </router-link>
      <div class="col-md-7 sat_right row">
        <div class="ml-3" v-if="result">
          <ul>
            <li>
              <b>Name: </b>
              <router-link
                :to="{ name: 'AdminViewUser', params: { id: result.user.id } }"
                v-if="isAdmin"
              >
                {{
                  user.displayName(
                    result.user.first_name,
                    result.user.last_name
                  )
                }}
              </router-link>
              <span v-else>
                {{
                  user.displayName(
                    result.user.first_name,
                    result.user.last_name
                  )
                }}
              </span>
            </li>
            <li>
              <b>Total Score: </b>
              <template v-if="practiceType == 'drill'">
                <b>
                  <span style="color: var(--themeColor);">{{ correctCount }}</span
                  >/{{ count }}
                </b>
              </template>
              <template v-else>
                <b style="color: var(--themeColor);">
                  {{ result.total_score }}
                </b>
                <b>
                  (<span style="color: var(--themeColor);">{{ correctCount }}</span
                  >/{{ count }})
                </b>
              </template>
            </li>
            <li>
              <b>Test Name: </b>
              <span>
                <el-tag class="mr-2" type="warning" v-if="isAdaptive">
                  <b>Adaptive</b>
                </el-tag>
                {{ exam.title }}
              </span>
            </li>
            <li>
              <b>Test Date: </b>
              <el-tooltip
                class="item"
                effect="dark"
                :content="moment(result.updated_at).format('HH:mm')"
                placement="top"
              >
                <span> {{ instant.formatGoogleTime(result.updated_at) }}</span>
              </el-tooltip>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <ScoreTable
      v-if="result && result.result"
      :total="result.total_score"
      :score="result.result"
      :practiceType="practiceType == 'drill' ? 'singlePractice' : practiceType"
      :isNew="isNew"
    ></ScoreTable>
    <template v-if="practices">
      <div v-for="(practice, passageIndex) in practices" :key="practice.id">
        <hr />
        <UserTestResultTable
          :examId="exam.id"
          :list="[practice]"
          :score="100"
          :passageIndex="passageIndex"
          :raw="100"
          :count="100"
          :userExamId="userExamId"
          :practiceType="
            practiceType == 'drill' ? 'singlePractice' : practiceType
          "
        ></UserTestResultTable>
      </div>
      <div class="mb-4" v-if="tag_analysis_arr && tag_analysis_arr.length > 0">
        <hr />
        <h5 class="title">
          My Performance Breakdown
        </h5>
        <div
          v-for="(tag_analysis, key, index) in tag_analysis_arr"
          :key="index"
          class="mb-4"
        >
          <Performance
            v-if="tag_analysis && tag_analysis.length > 0"
            section="Reading"
            :tagType="key"
            :tags="tag_analysis"
          ></Performance>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import _ from "lodash";
import { user, instant } from "@ivy-way/material";
import Breadcrumb from "@/components/Breadcrumb";
import SAT from "@/apis/sat";
import UserTestResultTable from "@/views/satUserTestResult/components/UserTestResultTable.vue";
import ScoreTable from "@/views/satUserTestResult/components/ScoreTable.vue";
import ScoreHistory from "@/views/satUserTestResult/components/ScoreHistory.vue";
import Performance from "@/views/satUserTestResult/performance/Index.vue";
import moment from "moment";
import roleMixin from "@/mixins/role";

export default {
  metaInfo() {
    return {
      title: "TestResults - " + this.CompanyName
    };
  },

  components: {
    Breadcrumb,
    UserTestResultTable,
    ScoreTable,
    ScoreHistory,
    Performance
  },

  mixins: [roleMixin],

  props: [],
  data() {
    return {
      moment,
      exam: null,
      practices: null,
      result: null,
      tag_analysis: null,
      userAnswers: [],
      count: 0,
      isNew: false,
      leaveSeconds: 0,
      leaveTimes: 0,
      timeTypeArr: {
        "1.5": "Time and One-Half (+50%)",
        "2": "Double Time (+100%)",
        "0": "Not Timed (∞)"
      }
    };
  },
  computed: {
    ...mapState("user", ["lang"]),
    leaveCountText() {
      let times = "time";
      if (this.lang.value === "EN-US") {
        if (this.leaveTimes <= 1) {
          times = this.leaveTimes + " " + "time";
        } else {
          times = this.leaveTimes + " " + "times";
        }
      } else {
        times = this.leaveTimes + " " + "次";
      }
      return times;
    },
    second() {
      let second = "second";
      if (this.lang.value === "EN-US") {
        if (this.leaveSeconds <= 1) {
          second = "second";
        } else {
          second = "seconds";
        }
      } else {
        second = "秒";
      }
      return second;
    },
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    practiceType() {
      return this.$route.query.practiceType === "adaptivePractices"
        ? "composePractices"
        : this.$route.query.practiceType;
    },
    isAdaptive() {
      return (
        this.result.other_status &&
        this.result.other_status.adaptive_practice_id
      );
    },
    userExamId() {
      return this.$route.params.id;
    },
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    correctCount() {
      let correctCount = 0;
      if (this.practices) {
        this.practices.forEach(item => {
          correctCount += item.correctCount;
        });
      }
      return correctCount;
    },
    tag_analysis_arr() {
      let res = null;
      if (this.tag_analysis) {
        res = _.groupBy(this.tag_analysis, "sat_question_tag_type");
      }
      console.log(res);
      return res;
    }
  },
  watch: {},

  async mounted() {
    if (
      this.practiceType === "composePractices" ||
      this.practiceType === "composePractice"
    ) {
      const res = await SAT.getComposePracticeResult(this.userExamId);
      this.exam = res.compose_practice.exam;
      this.practices = res.compose_practice.practices;
      this.result = res.result;
      this.isNew = res.is_new ? true : false;
      if (this.isAdaptive) {
        this.isNew = true;
      }
      this.tag_analysis = res.tag_analysis;
    } else if (
      this.practiceType === "singlePractice" ||
      this.practiceType === "practice" ||
      this.practiceType === "drill"
    ) {
      const res = await SAT.getPracticeResult(this.userExamId);
      this.exam = res.practice.exam;
      this.practices = [res.practice];
      this.result = res.result;
      this.tag_analysis = res.tag_analysis;
    }
    if (this.result.other_status && this.result.other_status.leaveInfo) {
      this.leaveSeconds = this.result.other_status.leaveInfo.leaveSeconds
        ? this.result.other_status.leaveInfo.leaveSeconds
        : 0;
      this.leaveTimes = this.result.other_status.leaveInfo.leaveTimes
        ? this.result.other_status.leaveInfo.leaveTimes
        : 0;
    }
    this.getUserAnswers();
  },
  methods: {
    // moment() {
    //   return moment();
    // },
    getUserAnswers() {
      let count = 0;
      this.practices.forEach(practice => {
        let arr = [];
        let correctCount = 0;

        practice.exam.exam_questions.forEach(question => {
          count++;
          let answer = null;
          this.result.user_exam_answers.forEach(userAnswer => {
            if (question.id === userAnswer.exam_question.id) {
              answer = userAnswer;
              if (userAnswer.is_correct === 1) {
                correctCount++;
              }
            }
          });
          question["userAnswer"] = answer;
          arr.push(answer);
        });
        practice["correctCount"] = correctCount;
        this.userAnswers.push(arr);
      });
      this.count = count;
    }
  }
};
</script>

<style scoped>
.title {
  color: #494949;
  font-size: 1.5rem;
  border: 0;
  padding: 0;
  border-left: 4px solid var(--themeColor);
  padding-left: 15px;
  /* line-height: 16px; */
  margin-bottom: 20px;
}
.sat_left {
  display: flex;
  color: var(--themeColor);
  text-decoration: none;
}

.sat_left > span {
  font-size: 52px;
}
.sat_left > p:first-child {
  font-size: 140px;
  font-weight: 700;
  line-height: 140px;
  margin-bottom: 0px;
}
.sat_right {
  padding-left: 0px;
  padding-top: 30px;
  /* border-bottom: 1px solid#DCDFE6; */
}
.sat_right ul {
  clear: both;
}
.sat_right ul li {
  font-size: 20px;
  line-height: 30px;
}
.sat .sat_right ul {
  list-style: none;
  position: relative;
  padding-left: 0;
}
@media screen and (max-width: 768px) {
  .container {
    padding: 15px;
  }
  .sat_left {
    display: none;
  }

  .sat_right {
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 1rem;
  }
  .sat_right ul li {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
</style>
